//
// Banner Heading styles
// --------------------------------------------------

.banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 35vh;
  display: flex;
  align-items: center;
  border-bottom:.25rem solid $white;

  &-heading {
    color: $white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-shadow: 2px 0px 14px #000000;
  }

  &.lg {
    min-height: 53vh;
  }

}

 // Divisions Box
 .division-heading {
   &-container {
     @extend .container;
     position: relative;
     height: 125px;
  }

  background-color: $white;
  margin: 0;
  border: solid .5rem $white;
  width:240px;
  height: 240px;
  position:absolute;
  left:0;
  right:0;
  top: -7rem;
  margin: 0 auto;
  
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    text-align: center;
  }

  .image {
      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
    }
  }

  @each $color, $value in $theme-colors {
    .hover-#{$color} {
      position: relative;
      background-color: #{$value};
      width: 100%;
      height: 100%;

      .image {
        opacity: 0;
        transition: opacity 0.35s;
      }
      
      .icon {
        opacity: 1;
        transition: opacity 0.35s;
      }
    }
  }
}
//
// Division teaser styles - image with info and view site button
// --------------------------------------------------


.division-teaser {
    border-bottom:2px solid $lt-gray;
    padding-bottom:3rem;
    margin-top:3rem;

    &:first-of-type {
        margin-top:0;
    }

    &:last-of-type {
        padding-bottom:0;
        border-bottom:0px solid $lt-gray;
    }

    .division-teaser-img {
        width:230px;

        @include media-breakpoint-down(sm) {
          margin-bottom:1rem;
        }
    }

    .division-teaser-info {
        .more-info {

            .btn-div {
                @include media-breakpoint-down(xs) {
                    width:100%;
                }
                .btn {
                    padding: .5rem 2.5rem;
                }
            }

            .product-image {
                width:230px;
            }
        }
    }
}
// Project type/characters
//

.divisions-title-container {
      @extend .container;
      position: relative;
      padding-top: 4rem;
      padding-bottom: 6rem;
      text-align: center;

      padding-left: 3rem;
      padding-right: 3rem;

      @include media-breakpoint-up(lg) {
            max-width: 1333px;
      }

      @include media-breakpoint-only(sm) {
            padding-left: 5rem;
            padding-right: 5rem;
      }

      @include media-breakpoint-up(md) {
            padding-left: 10rem;
            padding-right: 10rem;
      }

      @include media-breakpoint-only(xl) {
            padding-left: 12rem;
            padding-right: 12rem;
      }
            
      &::before, 
      &::after {
            content: "";
            position: absolute;
            top: 5rem;
            width: 50%;
            height: 6rem;
            border-color: $white; 
            border-style: dotted;            
            z-index: 1;

            @include media-breakpoint-up(lg) {
                  height: 4rem;
            }
      }
      
      &::before {
            left: .5rem;
            border-width: 1px 0 0 1px;
      }
      
      &::after {
            right: .5rem;
            border-width: 1px 1px 0 0;
      }

      .divisions-title {
            margin-bottom: 0;
            display: block;
            background-color: $black;
            position: relative;
            color: $white;
            z-index: 10;
      }
}
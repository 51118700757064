// Project tabs
//

.mb-tabs {
      .nav-tabs {
            border-color: $blue;
            border-width: .25rem;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                  flex-direction: row;
            }
      }

      .nav-item {
            margin-top: -.65rem;            

            @include media-breakpoint-up(lg) {
                  margin-top: 0;
                  padding-bottom: 0;
            }

            &:not(:last-of-type) {
                  .nav-link {
                        @include media-breakpoint-down(md) {
                              padding-bottom: 1.65rem;
                        }
                  }                  
            }
      }

      .nav-link {
            @extend .bg-image;
            color: $black;
            position: relative;
            padding-top: 1rem;
            padding-bottom: 1rem;            
            border-top-right-radius: 1rem;
            border-width: 0;
            &:not(.active)::before {
                  content: '';
                  border-top-right-radius: 1rem;
                  position: absolute;
                  left: 0;                  
                  top: 0;
                  width: 100%;
                  height: 100%;
                  opacity: .75;
                  background-color: $white;
            }

            &-text {
                  position: relative;
                  z-index: 10;
                  @extend .text-uppercase;
                  color: $body;
                  font-weight: 700;
                  font-size: 1rem;
                  @include media-breakpoint-up(md) {
                        font-size: 1.5rem;
                  }
            }

            &.active {
                  background-color: transparent;
                  .nav-link-text {
                        color: $white;
                  }
            }
      }

      .tab-pane {
            padding: 2rem;

            .card-divisions {
                  @extend .card;
                  padding: 1rem;
                  height: 100%;
                  align-items: center;
                  justify-content: center;

                  &:hover {
                        @extend .shadow;
                  }
            }

            .card-locations {
                  @extend .card;
                  @extend .shadow;
                  padding: 1rem;
                  height: 100%;
                  align-items: center;
                  justify-content: flex-start;
                  text-align: center;

                  &-title {
                        @extend .display-3;
                        @extend .text-uppercase;
                        color: $blue;
                        display: block;
                        font-weight: 700;
                        margin-bottom: 0;

                        small {
                              color: $body;
                              font-size: 1rem;
                              display: block;
                        }
                  }
            }
      }
}
//
// History styles
// --------------------------------------------------

.history-bg-image {
    padding:4.5rem 1rem;
    background-repeat:no-repeat;
    background-position:left center;
    background-size:cover;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        padding:2rem 1rem;
    }

    @include media-breakpoint-down(md) {
        padding:3rem 1rem;
    }
}

.history-card-locations {
      @extend .shadow;
      padding: 1rem;
      align-items: center;
      justify-content: flex-start;
      text-align: center;

      &-title {
            @extend .display-3;
            @extend .text-uppercase;
            color: $blue;
            display: block;
            font-weight: 700;
            margin-bottom: 0;

            small {
                  color: $body;
                  font-size: 1rem;
                  display: block;
                  font-weight:700;
            }
      }
}
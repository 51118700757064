//Bootstrap variable overrides


// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

//
// Color system
//

// Main
$primary:    #DA291C;
$mb-red:     #9c1721;
$mb-gray:    #55697b;
$mb-green:   #61805d;
$mb-blue:    #319faf;
$mb-dk-blue: #0B7184;
$mb-yellow:  #f9a13a;

// Grays
$dk-gray:      #5b616a;
$gray:         #999da2;
$lt-gray:      #EEEFEF;
$tinted-gray:  #dedede;

$blue:         #245586;
$lt-blue:      #739DBF;

$body:      #212529;

$white:     #ffffff;
$black:     #000000;

// Adding new color to default Bootstrap's $theme-colors map
$theme-colors: (
    "primary":      $primary,
    "mb-red":       $mb-red,
    "mb-gray":      $mb-gray,
    "mb-green":     $mb-green,
    "mb-blue":      $mb-blue,
    "mb-dk-blue":   $mb-dk-blue,
    "mb-yellow":    $mb-yellow,
    "dk-gray":      $dk-gray,
    "lt-gray":      $lt-gray,
    "gray":         $gray,
    "body":         $body,
    "white":        $white,
    "black":        $black
);

// Object Fit
$object-fits: ( 
    "fill":       fill, 
    "contain":    contain,
    "cover":      cover,
    "scale-down": scale-down,
    "none":      none
);

// Object Fit
$object-positions: ( 
    "top":      top, 
    "bottom":   bottom,
    "left":     left,
    "right":    right,
    "center":   center,
    "unset":    unset
);

$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2),
    6: ($spacer * 3),
    7: ($spacer * 4),
    8: ($spacer * 5),
    9: ($spacer * 6),
    10: ($spacer * 7)
);

$letter-space: 1rem;
$letter-space: (
    0: 0,
    1: ($letter-space * .15),
    2: ($letter-space * .25),
    3: ($letter-space * .5),
    4: $letter-space
);

$column-count: (
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                        true;
$enable-responsive-font-sizes:        true;
$enable-rounded:                      false;

// Font family
//
$font-family-nunitosans:       'Nunito Sans', sans-serif;
$font-family-robotocond:       'Roboto Condensed', sans-serif;

$font-families: (
    "nunitosans":          $font-family-nunitosans,
    "robotocond":          $font-family-robotocond,

);

$body-color:                $body;
$font-family-base:          $font-family-nunitosans;
$headings-font-family:      $font-family-nunitosans;

// Font sizes
$font-size: 1rem;
$font-size-xxs:    $font-size * .625;
$font-size-xs:     $font-size * .75;
$font-size-sm:     $font-size * .875;
$font-size-md:     $font-size * 1.125;
$font-size-lg:     $font-size * 1.25;
$font-size-xl:     $font-size * 1.375;
$font-size-xxl:    $font-size * 1.5;

$font-sizes: (
    xxs:    $font-size-xxs,
    xs:     $font-size-xs,
    sm:     $font-size-sm,
    base:   $font-size,
    md:     $font-size-md,
    lg:     $font-size-lg,
    xl:     $font-size-xl,
    xxl:    $font-size-xxl
);

// Font weights
$font-weights: (
    100:    100,
    200:    200,
    300:    300,
    400:    400,
    500:    500,
    600:    600,
    700:    700,
    800:    800,
    900:    900,
    950:    950
);

$font-weight-base:            400;
$headings-font-weight:        600;

//$display1-size:               $font-size-base * 2.5;
//$display2-size:               $font-size-base * 2;
//$display3-size:               $font-size-base * 1.75;
//$display4-size:               $font-size-base * 1.5;

//$display1-weight:             400;
//$display2-weight:             400;
//$display3-weight:             400;
//$display4-weight:             400;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            400;

$blockquote-small-color:      $gray-600;
$blockquote-small-font-size:  $font-size-base;
$blockquote-font-size:        $font-size-base * 1.25;

$hr-border-color:             $body;

$btn-font-weight:             500;
$btn-padding-x:               1.5rem;
$btn-padding-y:               .75rem;

// Navs

$nav-link-padding-y:                      .75rem !default;
$nav-link-padding-x:                      1.25rem !default;
$nav-link-font-weight:                    normal !default;
$nav-link-transition:                     color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out, border-color .25s ease-in-out !default;
$nav-link-icon-size:                      $font-size-base !default;
$nav-link-disabled-color:                 $gray-600 !default;

$nav-link-dark-color:                     $gray;
$nav-link-dark-hover-color:               $primary;
$nav-link-dark-active-color:              $primary;

$nav-link-light-color:                    rgba($white, .65) !default;
$nav-link-light-hover-color:              $white !default;
$nav-link-light-active-color:             $white !default;

$nav-tabs-link-color:                     $nav-link-dark-color !default;
$nav-tabs-link-hover-color:               $nav-link-dark-hover-color !default;
$nav-tabs-link-active-color:              $nav-link-dark-active-color !default;
$nav-tabs-link-active-bg:                 transparent !default;
$nav-tabs-link-active-border-color:       $nav-link-dark-active-color !default;

$nav-pills-padding-y:                     .675rem !default;
$nav-pills-font-size:                     $input-btn-font-size !default;
$nav-pills-link-color:                    $nav-link-dark-color !default;
$nav-pills-link-bg:                       $secondary !default;
$nav-pills-link-hover-color:              $gray-700 !default;
$nav-pills-link-hover-bg:                 darken($nav-pills-link-bg, 6%) !default;

// Navbar

$navbar-padding-y:                    $spacer * .75 !default;

$navbar-nav-link-padding-y:           1rem;
$navbar-nav-link-padding-x:           2rem;

$navbar-toggler-padding-y:            .75rem !default;
$navbar-toggler-padding-x:            .75rem !default;
$navbar-toggler-font-size:            $font-size-base !default;
$navbar-toggler-border-radius:        0;

$navbar-light-color:                  $body;
$navbar-light-hover-color:            $white;
$navbar-light-hover-bg:               $black;
$navbar-light-active-color:           $white;
$navbar-light-active-bg:              $black;
$navbar-light-disabled-color:         $gray-600 !default;
$navbar-light-toggler-border-color:   transparent;

$navbar-dark-color:                   rgba($white, .65) !default;
$navbar-dark-hover-color:             $white !default;
$navbar-dark-active-color:            $white !default;
$navbar-dark-disabled-color:          rgba($white, .4) !default;
$navbar-dark-toggler-border-color:    transparent;

$navbar-dropdown-min-width:           12.5rem !default;
$navbar-mega-dropdown-column-width:   15rem !default;

$navbar-tool-transition:              color .25s ease-in-out;
$navbar-tool-icon-box-size:           2.5rem;
$navbar-tool-icon-font-size:          1.25rem;
$navbar-tool-label-size:              1.25rem;
$navbar-tool-label-bg:                $primary;
$navbar-tool-label-color:             $white !default;
$navbar-tool-label-font-size:         $font-size * .75;
$navbar-tool-label-font-weight:       500;
$navbar-tool-text-font-size:          $font-size-sm;

$navbar-tool-dropdown-bg:             rgba($white, .98);

$navbar-light-tool-color:             $gray;
$navbar-light-tool-hover-color:       $gray-900 !default;
$navbar-light-tool-text-small-color:  $gray-600 !default;

$navbar-dark-tool-color:              $white !default;
$navbar-dark-tool-hover-color:        $white !default;
$navbar-dark-tool-text-small-color:   rgba($white, .5) !default;

$navbar-stuck-box-shadow:             $box-shadow !default;

// Dropdowns
$dropdown-bg:                       rgba($white, .98);
$dropdown-border-color:             $black;
$dropdown-divider-bg:               $border-color !default;
$dropdown-box-shadow:               none;

$dropdown-link-color:               $body;
$dropdown-link-hover-color:         $mb-red;
$dropdown-link-hover-bg:            transparent;
$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           $black;
$dropdown-link-transition:          all .25s ease-in-out !default;

$dropdown-item-padding-y:           0.75rem;
$dropdown-item-padding-x:           1.3rem !default;
$dropdown-item-font-size:           $font-size-base;
$dropdown-item-icon-size:           $dropdown-item-font-size !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width:                1px !default;
$border-color:                $black;
$border-light-color:          rgba($white, .12) !default;

$border-radius:               .3125rem !default;
$border-radius-lg:            .4375rem !default;
$border-radius-sm:            .1875rem !default;

$box-shadow:                  0 .25rem 0.5625rem -.0625rem rgba($black, .03), 0 .275rem 1.25rem -.0625rem rgba($black, .05) !default;
$box-shadow-lg:               0 .3rem 1.525rem -.375rem rgba($black, .1) !default;
$box-shadow-sm:               0 .125rem 0.3rem -.0625rem rgba($black, .03), 0 .275rem .75rem -.0625rem rgba($black, .06) !default;

$caret-width:                 .275em !default;

$component-active-bg:         $mb-red;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              lighten($component-active-bg, 25%);
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

// Widgets

$widget-title-font-size:              $h6-font-size !default;
$widget-title-font-weight:            $headings-font-weight !default;
$widget-transition:                   color .25s ease-in-out !default;

// Widget: Categories
$widget-categories-font-size:            $font-size-base * .9375 !default;
$widget-categories-color:                  $gray-700 !default;
$widget-categories-hover-color:            $primary !default;
$widget-categories-active-color:           $primary !default;
$widget-categories-indicator-size:         1.375rem !default;
$widget-categories-indicator-icon-size:    8px !default;
$widget-categories-indicator-hover-bg:     rgba($primary, .1) !default;
$widget-categories-indicator-hover-color:  $primary !default;
$widget-categories-indicator-active-bg:    rgba($primary, .1) !default;
$widget-categories-indicator-active-color: $primary !default;

// Widget: Links
$widget-links-font-size:              $font-size-base;
$widget-links-font-weight:            normal !default;
$widget-links-dark-color:             $white;
$widget-links-dark-hover-color:       $white;
$widget-links-dark-active-color:      $white;
$widget-links-light-color:            $white;
$widget-links-light-hover-color:      $white !default;
$widget-links-light-active-color:     $white !default;

// Widget: Featured products, Cart common styles
$widget-product-title-color:          $headings-color !default;
$widget-product-title-hover-color:    $primary !default;
$widget-product-title-font-size:      $font-size-sm !default;
$widget-product-title-font-weight:    $headings-font-weight !default;
$widget-product-meta-font-size:       $font-size-sm !default;


// Topbar

$topbar-padding-y:                    .625rem !default;
$topbar-font-size:                    $font-size-sm !default;

$topbar-light-color:                  rgba($gray-700, .9) !default;
$topbar-light-muted-color:            rgba($gray-600, .9) !default;
$topbar-light-hover-color:            $gray-800 !default;
$topbar-light-active-color:           $gray-800 !default;
$topbar-light-icon-color:             $primary;

$topbar-dark-color:                   rgba($white, .65) !default;
$topbar-dark-muted-color:             rgba($white, .45) !default;
$topbar-dark-hover-color:             $white !default;
$topbar-dark-active-color:            $white !default;
$topbar-dark-icon-color:              $primary !default;

// Side navigation
$sidenav-width:                           19rem !default;
$sidenav-scroll-offset-top:               5rem !default; // This value should match the height of .sidenav-header
$sidenav-padding-x:                       1.5rem !default;

// Breadcrumbs

$breadcrumb-font-size:              .875rem;

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $body;
$breadcrumb-active-color:           $mb-red;
$breadcrumb-divider:                quote(">");

$breadcrumb-border-radius:          0;

// Pagination

$pagination-color:                  $mb-red;
$pagination-bg:                     $white;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
$pagination-focus-outline:          0;

$pagination-hover-color:            $mb-red;
$pagination-hover-bg:               $gray-200;

$pagination-active-color:           $white;
$pagination-active-bg:              $mb-red;
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;

// SVG Icons
$user-icon:   url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.3 22.34'%3E%3Cdefs/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' d='M0 0h22.3v22.34H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg fill='none' stroke='%23231f20' stroke-width='1.25' clip-path='url(%23a)'%3E%3Ccircle cx='11.15' cy='9.96' r='5.5'/%3E%3Cpath d='M11.15 21.72A10.55 10.55 0 10.63 11.17a10.54 10.54 0 0010.52 10.55z'/%3E%3Cpath d='M17.48 19.9V19a2.83 2.83 0 00-2.87-2.9h-7A2.83 2.83 0 004.73 19v.6'/%3E%3C/g%3E%3C/svg%3E");
$saved-icon:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.4 13.6'%3E%3Cdefs/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' d='M0 0h15.4v13.6H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23231f20' d='M6.8 13.6A6.85 6.85 0 01.5 7.4a7 7 0 011.6-5A6.57 6.57 0 016.8 0h1.8a6.85 6.85 0 016.3 6.2 7 7 0 01-1.6 5 6.94 6.94 0 01-4.6 2.4zm0-12.4A5.59 5.59 0 003 3.1a5.23 5.23 0 00-1.3 4.1 5.59 5.59 0 005.2 5.2h1.8a5.32 5.32 0 003.8-2 5.23 5.23 0 001.3-4.1 5.59 5.59 0 00-5.2-5.2z'/%3E%3C/g%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23231f20' d='M12.1 5.8H8.5V2.1H7.3v3.7H3.7v1.1h3.6v3.7h1.2V6.9h3.6V5.8z'/%3E%3C/g%3E%3C/svg%3E");
$chat-icon:   url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 22.11'%3E%3Cdefs/%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' d='M0 0h25v22.11H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M22.28 1.44a1.49 1.49 0 01.88.42 1.15 1.15 0 01.4.62v9a1.11 1.11 0 01-.4.61 1.43 1.43 0 01-.88.42h-3.56v3.6L15.3 12.9l-.42-.39H8.64a1.41 1.41 0 01-.88-.42 1.11 1.11 0 01-.4-.61v-9a1.15 1.15 0 01.4-.62 1.46 1.46 0 01.88-.42h13.64m0-1.44H8.64a2.9 2.9 0 00-2.73 2.48v9a2.89 2.89 0 002.73 2.47h5.7l5.16 4.85a.46.46 0 00.71-.32v-4.53h2.1a2.89 2.89 0 002.69-2.5v-9A2.9 2.9 0 0022.28 0z'/%3E%3Ccircle cx='12.2' cy='7.09' r='.81'/%3E%3Ccircle cx='15.57' cy='7.09' r='.81'/%3E%3Ccircle cx='18.95' cy='7.09' r='.81'/%3E%3Cpath d='M13.89 15.74h-3.72l-.42.39-3.47 3.23v-3.62H2.74a1.56 1.56 0 01-.9-.42 1.22 1.22 0 01-.4-.61v-9a1.18 1.18 0 01.4-.61 1.52 1.52 0 01.9-.43h1.39V3.26H2.74A2.91 2.91 0 000 5.74v9a2.9 2.9 0 002.74 2.47h2.12v4.53a.43.43 0 00.45.4.45.45 0 00.23-.14l5.2-4.85h4.69z'/%3E%3C/g%3E%3C/svg%3E");



// Bootstrap and its default variables
// Optional
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/alert";
//
// Product pages filter search box
// --------------------------------------------------

.filter-search-box {
    border-radius:0.5rem;
    padding:1rem;
    margin-bottom:2rem;
    background-color:$lt-gray;

    .filter-search-box-section {
        form {
            label {
                font-size: 1rem;
                color:$mb-red;
                font-weight:700;
            }

            .form-check {
                margin-bottom:0.5rem;

                label {
                    color:$body;
                    font-weight:normal;
                }
            }
        }
    }
}
//
// Input group
// --------------------------------------------------


// Addon overlay (absolute positioned addon)

.input-group-overlay {
  position: relative;
  width: 100%;

  .form-control {
    height: auto;
    &::placeholder {
        font-size: $font-size-xs;
      }
    }

  @include media-breakpoint-up(xl) {
    width: 30%;

    .form-control {

      & + .input-group-text {
        background-color: transparent;
        border: none;
        transition: {
          property: all;
          duration: .25s;
        }        
      }
    }

    &:focus, &:focus-within {

      .form-control {
        box-shadow: none;
        background-color: darken($white, 2%);
        width: 100%;

        & + .input-group-text {
            background-color: darken($white, 2%);
            border-color: darken($white, 2%);            
        }
      }      
    }

    .input-group-text:focus, .input-group-text:active, .input-group-text:focus, .input-group-text:hover {
      outline: none;
      color: $white;
      background-color: $mb-red !important;
    }

  }
  
  .prepended-form-control {
    padding-left: 2.5rem;
  }
  .appended-form-control {
    padding-right: 2.5rem;
  }
}
.input-group-append-overlay,
.input-group-prepend-overlay {
  display: flex;
  position: absolute;
  top: 0;
  height: 100%;  

  .input-group-text {
    border: 0;
    background-color: transparent;
  }
  z-index: 5;
}
.input-group-append-overlay {
  right: 0;
  .form-control,
  .custom-select {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.input-group-prepend-overlay {
  .form-control,
  .custom-select {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}


// Sizing

.input-group-sm {
  .appended-form-control {
    padding-right: 2rem;
  }
  .prepended-form-control {
    padding-left: 2rem;
  }
  > .input-group-prepend-overlay > .input-group-text,
  > .input-group-append-overlay > .input-group-text {
    padding: $input-padding-y-sm $input-padding-x-sm;
    @include font-size($input-font-size-sm);
  }
}
.input-group-lg {
  .appended-form-control {
    padding-right: 3rem;
  }
  .prepended-form-control {
    padding-left: 3rem;
  }
  > .input-group-prepend-overlay > .input-group-text,
  > .input-group-append-overlay > .input-group-text {
    padding: $input-padding-y-lg $input-padding-x-lg;
    @include font-size($input-font-size-lg);
  }
}

//
// Widgets
// --------------------------------------------------


// Component common styles

.widget {
  .cz-carousel .tns-nav { padding-top: .5rem; }
}
.widget-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget-title {
  margin-bottom: $spacer * 1.125;
  font: {
    size: $widget-title-font-size;
    weight: $widget-title-font-weight;
  }
}


// Widget: Categories

.widget-categories {
  .card, .card-header {
    border: 0;
    border-radius: 0;
  }

  .card-body {
    padding: $card-spacer-y / 1.425 0;
  }
  
  .widget-list-item {
    padding-left: .75rem;
  }
  
  .widget-list:not([data-simplebar]) {
    border-right: $border-width * 2 solid $border-color;
    .widget-list-item {
      padding-right: 1rem;
      .widget-list:not([data-simplebar]) { border-right: 0; }
    }
  }
}


// Widget: Links

.widget-list-item {
  margin-bottom: $spacer * .375;
  &:last-child { margin-bottom: 0; }
}
.widget-list-link {
  display: block;
  transition: $widget-transition;
  color: $widget-links-dark-color;

  font: {
    size: $widget-links-font-size;
    weight: $widget-links-font-weight;
  }

  &:hover {
    color: $widget-links-dark-hover-color;
  }

  &.disabled {
    color: rgba($black, .65);
    pointer-events: none;
    cursor: default;
    line-height: 1;
  }
}

.active > .widget-list-link {
  color: $widget-links-dark-active-color;
}

.widget-light {
  .widget-list-link {
    color: $widget-links-light-color;
    &:hover {
      color: $widget-links-light-hover-color;
    }
  }
  .active > .widget-list-link {
    color: $widget-links-light-active-color;
  }
}


// Widget products common styles: Featured products, Cart

.widget-product-title {
  margin-bottom: $spacer / 4;
  font: {
    size: $widget-product-title-font-size;
    weight: $widget-product-title-font-weight;
  }
  > a {
    color: $widget-product-title-color;
  }
  &:hover > a {
    color: $widget-product-title-hover-color;
  }
}
.widget-product-meta {
  font-size: $widget-product-meta-font-size;
}


// Widget: Cart

.widget-cart-item {
  position: relative;
  .close {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: visibility .25s ease-in-out, opacity .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  .media {
    transition: all .25s ease-in-out;
  }
  &:hover {
    .media {
      transform: translateX($spacer * 1.125);
    }
    .close {
      visibility: visible;
      opacity: 1;
    }
  }
}
// Project utilities
//

// Text
//
@each $key, $val in $font-families {
    .font-family-#{$key}  {
        font-family: $val;
    }
}

.font-size {
    @each $size, $value in $font-sizes {
            &-#{$size} {
                  font-size: #{$value};
            }      
      }
}

.font-weight {
    @each $name, $value in $font-weights {
            &-#{$name} {
                  font-weight: #{$value} !important;
            }      
      }
}

@each $count, $value in $column-count {
      .d-column-#{$count} {
            column-count: #{$value};
            column-width: 165px;
            column-gap: 1.25rem;
            widows: 1;
            orphans: 1;
      }
}

@each $size, $value in $letter-space {
      .letter-spacing-#{$size} {
            letter-spacing: #{$value};
      }
}

@each $color, $value in $theme-colors {
      .hr-#{$color} {
            border-color: #{$value};
      }
}

.hr-small {
      margin-left: unset;
      width: 100px;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      border-width: 2px;      
}

@each $key, $val in $object-fits {
    .object-fit-#{$key} {
        object-fit: $val;
    }
}

@each $key, $val in $object-positions {
    .object-position-#{$key} {
        object-position: $val;
    }
}

@each $color, $value in $theme-colors {
      .list-#{$color} {
            padding-left: 1rem;
            list-style: none;

            li {
                  margin-bottom: 0.5rem;

                  &::before {
                        content: "\2022";
                        color: #{$value};
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                  }
            }            
      }
}

@each $color, $value in $theme-colors {
      .btn-group-#{$color} {            

            .btn-link {
                  padding-top: 0;
                  padding-bottom: 0;
                  border-right: solid 1px #{$value};
                  color: #{$value};
                  
                  &:focus,
                  &.focus {
                        outline: 0;
                        box-shadow: none !important;
                  }

                  &:last-of-type {
                        border: 0;
                  }
            }

            @media (max-width: 510px) {
                  display: flex;
                  flex-direction: column;
                  align-items: stretch;

                  .btn-link {
                        border-bottom: solid 1px #{$value};
                        border-right: 0;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                  }
            }
      }
}

@each $color, $value in $theme-colors {
      .bg-gradient-#{$color} {
            background: linear-gradient(0deg, $white 0%, #{$value} 100%);
      }
      .bg-page-gradient-#{$color} {
            background: linear-gradient(0deg, $white 75%, #{$value} 100%);
      }
}

.bg-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
}

.col-img {
      width: 100%;
      max-width: 255px;
      height: auto;
      display: block;
      margin: 0 auto;
}

.row-bg {
      background-repeat:no-repeat;
      background-position:top left;
      background-size:auto 100%;

      @include media-breakpoint-down(md) {
            background-size:auto;
      }
}

.list-double-chevron {
      padding-left: 1rem;
      list-style: none;      

      li {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            &::before {
                  content: '';                  
                  display: inline-block;
                  width: 16px;
                  height: 12px;
                  margin-right: 9px;
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23444' d='M57.7 38.1l209.4 209.4c4.7 4.7 4.7 12.3 0 17L57.7 473.9c-4.7 4.7-12.3 4.7-17 0l-19.8-19.8c-4.7-4.7-4.7-12.3 0-17L201.6 256 20.9 74.9c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0zm143 0l-19.8 19.8c-4.7 4.7-4.7 12.3 0 17L361.6 256 180.9 437.1c-4.7 4.7-4.7 12.3 0 17l19.8 19.8c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17L217.7 38.1c-4.7-4.7-12.3-4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
                  background-repeat: no-repeat;
                  background-position: center;
            }
      }            

      &-white {

            li::before {
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M57.7 38.1l209.4 209.4c4.7 4.7 4.7 12.3 0 17L57.7 473.9c-4.7 4.7-12.3 4.7-17 0l-19.8-19.8c-4.7-4.7-4.7-12.3 0-17L201.6 256 20.9 74.9c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0zm143 0l-19.8 19.8c-4.7 4.7-4.7 12.3 0 17L361.6 256 180.9 437.1c-4.7 4.7-4.7 12.3 0 17l19.8 19.8c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17L217.7 38.1c-4.7-4.7-12.3-4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
            }
                        
      }
}

footer {
      border-top:1.75rem solid $lt-gray;

      a.text-body {
            @include hover-focus {
                  text-decoration: none;
                  color:$primary !important;
            }
      }

      .footer-comp-logo {
            margin: 0 5px;

            &:first-of-type {
                  margin-left:0;
            }

            &:last-of-type {
                  margin-right:0;
            }

            img {
                  max-height:40px !important;
            }
      }
}

.hr {
      &.mb-red 
      {
            border-top:2px solid $mb-red;
      }
}

.certification-list {
      .row {
            border-top: 1px solid $gray;

            &:last-of-type {
                  border-bottom: 1px solid $gray;
            }
      }
}

.literature-image {
      max-width:200px;
}

.literature-list {
      li {
            a {
                  color:$mb-red;
                  font-weight:700;

                  @include hover-focus {
                        text-decoration: none;
                        color:darken($mb-red, 15%);
                  }
            }

            span {
                  color:$mb-gray;
            }
      }
}

.alert-mb-red {
      background-color:$mb-red;
      color:$white;
      font-size:0.85rem;

      .close {
            line-height: 0.85;
            font-size: 1.25rem;
      }
}

.marsh-ad {
      padding:1rem;

      .close {
            margin-top:-1rem;
            margin-right:-1rem;
      }

      .learn-more {
            display: inline-block;
            margin-top: -1rem;
            font-size: 1.15rem;

            i {
                  font-size: 1.75rem;
            }
      }
}

.marsh-ad-sticky {
      float: right;
      position: sticky;
      top: calc(50vh - (527px / 2)); // scrolls in the middle of the screen.
      //top: calc(100vh - 550px);  // for it to be closer to the bottom of the screen when scrolling.
      margin-top: -100px;
      z-index:100;

      .close {
            position:absolute;
            right:0;
            top:232px;
            padding: 0.75rem 0.75rem;
            text-shadow: 0 0px 0 #fff;
            opacity: 1;
            font-size: 1.75rem;
      }

      .btn {
            position:absolute;
            bottom:7px;
            right:27px;
            border-radius: 1.5rem;
            padding: 0.15rem 0.75rem;
            font-size: .875rem;
            line-height: 1.5;      
      }
}

.btn-orange {
      background-color:#F59420;
      color:$white;

      &:hover, &:focus {
            color:$white;
      }
}
//
// Carousel
// --------------------------------------------------
.carousel {
  border-bottom:.25rem solid $white;

  .carousel-item {
    &::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (3 / 4) * 100%;

      @include media-breakpoint-only(sm) {
        padding-top: (9 / 16) * 100%;
      }

      @include media-breakpoint-up(md) {
        padding-top: (1 / 2) * 100%;
      }

      @include media-breakpoint-up(xl) {
        padding-top: (6 / 17) * 100%;
      }
    }
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }
  }

  &-divisions {
    position: relative;
    height: 0;
    padding-top: calc(90% - 4rem);

    @include media-breakpoint-up(lg) {
      max-width: 1333px;
    }  

    @include media-breakpoint-only(sm) {
      height: 190px;
      padding-top: 0;
    }
    @include media-breakpoint-only(md) {
      height: 184px;
      padding-top: 0;
    }
    @include media-breakpoint-only(lg) {
      height: 184px;
      padding-top: 0;
    }

    @include media-breakpoint-only(xl) {
      height: 200px;
      padding-top: 0;
    }
    
    &-inner {
      top: -4rem;
      left: 2rem;
      width: calc(100% - 4rem);
      height: 100%;
      position: absolute !important;

      .carousel-cell {
        visibility:visible;
        width: 90%;
        @include media-breakpoint-up(sm) {
          width: 100%;
        }
        @extend .col-sm-6;
        @extend .col-md-4;
        @extend .col-lg;  

        @include media-breakpoint-up(lg) {
          max-width: 20%;
        }      
        transition: all 0.35s;
        
        figure {
          background-color: $white;
          position: relative;
          margin: 0;
          height: 100%;
          border: solid .5rem $white;
          
          .icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: .5rem;
            text-align: center;
          }

          .image {
              img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
            }
          }

          figcaption {
            display: none;
            opacity: 0;
            margin: 1rem .5rem 1.5rem;
            transition: all 0.35s;
          }

          @each $color, $value in $theme-colors {
            .hover-#{$color} {
              position: relative;
              background-color: #{$value};
              width: 100%;
              height: 100%;

              .image {
                opacity: 0;
                transition: opacity 0.35s;
              }
              
              .icon {
                opacity: 1;
                transition: opacity 0.35s;
              }
            }
          }

          &:hover {
            @extend .shadow;
          }
        }

        &.active {
          figure {
            .image {
              opacity: 1;
            }
            
            .icon {
              opacity: 0;
            }

            figcaption {
              opacity: 1;
              display: block;
            }
          }
        }
        .btn-gray {
          color:$white;

          @include hover-focus {
            color:$body;
          }
        }
      }
    }
  }

}

.carousel-caption {
  height: 100%;
  text-align: left;
  align-items: center;
  left: 5%;
  right: 5%;
  text-shadow: 2px 0px 14px $black;
  bottom: 0;

  @include media-breakpoint-up(xl) {
    left: 10%;
    right: 10%;
  }
}
  // Layer animations
  .carousel-item {
    user-select: none;
    .from-top, .from-bottom,
    .from-left, .from-right,
    .scale-up, .scale-down {
      transition: all .45s .3s ease-in-out;
      opacity: 0;
      backface-visibility: hidden;
    }
    .from-top {
      transform: translateY(-45px);
    }
    .from-bottom {
      transform: translateY(45px);
    }
    .from-left {
      transform: translateX(-45px);
    }
    .from-right {
      transform: translateX(45px);
    }
    .scale-up {
      transform: scale(0.8);
    }
    .scale-down {
      transform: scale(1.2);
    }
    .delay-1 {
      transition-delay: .5s;
    }
    .delay-2 {
      transition-delay: .7s;
    }
    .delay-3 {
      transition-delay: .9s;
    }
    .delay-4 {
      transition-delay: 1.1s;
    }
    .delay-5 {
      transition-delay: 1.5s;
    }
    &.active {
      .from-top, .from-bottom {
        transform: translateY(0);
        opacity: 1;
      }
      .from-left, .from-right {
        transform: translateX(0);
        opacity: 1;
      }
      .scale-up, .scale-down {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  /*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 0; }
.flickity-prev-next-button.next { right: 0; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
//
// Pagination styles
// --------------------------------------------------

.pagination {
    .page-item {
        margin-right:1rem;
        
        .page-link {
            border: 1px solid #ced4da;
        }
    }
}
//
// Form styles
// --------------------------------------------------

.form {
    .group-label {
        display:inline-block;
        max-width: 350px;
        width: 100%;
        background-color:$mb-red;
        font-weight:700;
        color:$white;
        padding: .25rem 2rem .25rem 1rem;
        margin-left:10px;
        margin-bottom: 1rem;
    }

    .form-control {
        border: 2px solid #ced4da;
    }

    .form-row > [class*=col-] {
        padding-right: 15px;
        padding-left: 15px;
    }

    .form-group {
        label {
            font-weight:700;

            .note {
                font-size:0.85rem;
                font-weight:normal;
            }
        }
    }
}

.footer-form {
    margin-top:0;
    border-left:1px solid #9c1721;

    .wrapper {
        max-width:440px;
    }

    //1662px
    @media (max-width: 1662px) {
          margin-top: 1.65rem;
          border-left:0;
    }
}